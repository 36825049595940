.save-and-continue-button {
    background: #124761 url(/static/assets/img/primary_gradient.gif) repeat-x bottom left;
    border-top: #4b9dc4 3px solid;
    border-right: #082f42 3px solid;
    border-bottom: #082f42 3px solid;
    border-left: #4b9dc4 2px solid;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.1em;
    color: #fff;
    padding: 0px 10px;
}

.secondary-button {
    background: #124761 url(/static/assets/img/secondary_gradient.gif) repeat-x bottom left;
    border-top: #e3f2fa 3px solid;
    border-right: #7eaec6 3px solid;
    border-bottom: #7eaec6 3px solid;
    border-left: #e3f2fa 2px solid;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.1em;
    color: #000;
    padding: 0px 10px;
}

.cancel-button {
    background: #124761 url(/static/assets/img/secondary_gradient.gif) repeat-x bottom left;
    border-top: #e3f2fa 3px solid;
    border-right: #7eaec6 3px solid;
    border-bottom: #7eaec6 3px solid;
    border-left: #e3f2fa 2px solid;
    font: Arial, Helvetica, sans-serif;
    font-size: 1.1em;
    color: black;
    padding: 0px 10px;
    margin-left: 15px;
}

.section-break-large {
    padding: 20px 0;
}

.section-break-medium {
    padding: 15px 0;
}

.section-break-small {
    padding: 5px 0;
}

.section-break-tiny {
    padding: 5px 0;
}

.section-break-micro {
    padding: 2px 0;
}

.thin-gray-border {
    border-top: 1px #e6e5e5 solid;
    margin: 15px 0;
}

.thin-black-border {
    border-top: 1px black solid;
    margin: 15px 0;
}

.required-text-red {
    color: #a01100;
}

.required-star {
    font-size: 24px;
    color: #A01100;
    vertical-align: middle;
    display: inline-block;
    line-height: 20px;
    height: 15px;
}

.float-right {
    float: right;
}

.danger-input-text {
    border: 2px solid #A01100 !important;
}

.danger-box {
    outline: 2px solid #A01100 !important;
    outline-offset: -2px;
}

.danger-box:focus {
    box-shadow: inset 1px 2px 0 rgb(0 0 0 / 8%), inset 0 0 0 1px #118289;
}

.danger-input {
  outline-style: auto !important;
  outline-color: #a01100 !important;
  outline-width: 2px !important;
  outline-offset: -2px !important;
}

.striped-rows:nth-child(even) {
    background-color: #e6e5e5;
}

.success-msg-text-green {
    color: #098500;
}

.centered-content {
    display: grid;
    place-items: center;
}

.child-div-inline div {
    display: inline-flex;
    align-items: center;
}

.row-space-between {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}

.light-gray-container {
    background: #eaf3f7 !important;
    border: #b4c0c6 2px solid !important;
}

.no-button {
    background: none;
    border: none;
    padding: 0;
    color: #a73400;
    text-decoration: underline;
}

.no-button:focus {
    color: #1e5e7d;
    text-decoration: none;
}

.inline-column {
    display: inline-flex;
    flex-direction: column;
}

.blue-link-text {
    color: #1665CF;
}

.legacy-red-link-text {
    color: #a73400;
}

.legacy-grey-link-text {
    color:grey;
}

.row-start-right {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
}

.screen-reader-only {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/* overwrite base bootstrap button class */
.btn {
  font-size: 1em;
}

/* Shared Tags */
.currentTag {
    padding: 3px 5px 1px 5px;
    margin: 0px 8px 8px 0px;
    display: inline-block;
    cursor: default;
}

.currentTagWrapper {
    text-overflow: ellipsis;
    min-width: initial;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
}

.currentTag mat-icon {
    margin-left: -5px;
    margin-top: -5px;
    transform: scale(0.8);
    vertical-align: middle;
}

.currentTag mat-icon:first-of-type {
    margin-left: 0px !important;
}

.tag_orange {
    background-color: #FADEC9;
    border-color: #49290E;
}

.tag_orange_unselected {
    background-color: rgb(250, 222, 201, .5);
    border-color: rgb(73, 41, 14, .5);
}

.tag_yellow {
    background-color: #FDECC8;
    border-color: #402C1B;
}

.tag_yellow_unselected {
    background-color: rgb(253, 236, 200, .5);
    border-color: rgb(64, 44, 27, .5);
}

.tag_green {
    background-color: #DBEDDB;
    border-color: #1C3829;
}

.tag_green_unselected {
    background-color: rgb(219, 237, 219, .5);
    border-color: rgb(28, 56, 41, .5);
}

.tag_blue {
    background-color: #D3E5EF;
    border-color: #183347;
}

.tag_blue_unselected {
    background-color: rgb(211, 229, 239, .5);
    border-color: rgb(24, 51, 71, .5);
}

.tag_purple {
    background-color: #E8DEEE;
    border-color: #412454;
}

.tag_purple_unselected {
    background-color: rgb(232, 222, 238, .5);
    border-color: rgb(65, 36, 84, .5);
}

.tag_red {
    background-color: #FFE2DD;
    border-color: #5D1715;
    color: initial !important;
}

.tag_red_unselected {
    background-color: rgb(255, 226, 221, .5);
    border-color: rgb(93, 23, 21, .5);
}

.tag_orange, .tag_yellow, .tag_green, .tag_blue, .tag_purple, .tag_red {
    border-radius: 2px;
}

.tag_orange_unselected, .tag_yellow_unselected, .tag_green_unselected, .tag_blue_unselected, .tag_purple_unselected, .tag_red_unselected {
    color: rgb(0, 0, 0, .6);
};
  /* End Shared Tags */