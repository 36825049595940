h1 {
    margin-top: 6px;
}

h2 {
    font-size: 1.625em;
}

h3 {
    font-size: 1.125em;
}

h4 {
    font-size: 0.938em;
    font-weight: 300;
    line-height: 1.1;
    text-transform: none;
    letter-spacing: normal;
    color: inherit;
}

.modal-backdrop.fade
{
  opacity: 0.5 !important;
}
.modal-open .modal
{
  opacity: 1 !important;
}

  @media (min-width: 768px) {
    .modal-dialog.modal-lg {
      width: 80%;
    }
  }
  .modal-dialog .panel {
    margin-bottom: 0
  }
  .red {
    color: #d33
  }
  #myGroups {
    padding-bottom: 25px
  }
  #myGroups.inactive .link, #myGroups.inactive .uxf-icon {
    color: #777
  }

  .modal-dialog {
      padding-top: 150px;
  }
